<template>
    <div style="overflow: scroll; padding-top: 8px;">
        <v-container style="height: 87vh; max-width: 900px; overflow:auto;-webkit-overflow-scrolling:touch;" :class="`scroll-hide-festy ${$vuetify.breakpoint.name == 'xs' ? 'pt-0 pl-5 pr-5' : ''}`" color="#f1f1f1">
            <v-row>
                <v-col cols="12">
                    <h1>Elige tu ciudad</h1>
                    <h2>Pide fácil, rápido y seguro en Festy.</h2>
                </v-col>
                <v-col
                v-for="(item, index) in zones" :key="index"
                cols="6"
                sm="3"
                >
                    <v-img
                        v-ripple
                        class="white--text align-center justify-center text-center text-wrap"
                        :src="require('./../assets/bg.png')"
                        width="200"
                        @click="setZoneUser(item)"
                    >
                        <h3 class="text-wrap">{{ item.attributes.name }}</h3>
                    </v-img>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import Parse from 'parse'
import axios from "axios";

export default {
    data() {
        return {
            zones: []
        }
    },

    watch: {
        '$store.state.address': function(){
            this.$router.push('/')
        }
    },

    mounted() {
        this.initialize();
    },

    methods: {
        async initialize () {
            
            let country = ''
            
            try {
                let response = await axios.get('https://ipinfo.io/json?token=c32e6b36c5ab34')
                if (response && response.data) {
                    country = response.data.country
                }else{
                    country = "CO"
                }
            } catch (error) {
                country = "CO"
            }

            try {

                let params = {
                    country: country
                }
                
                let zones = await Parse.Cloud.run("getAppConfigWhitoutLocation", params);
                this.zones = zones
                let zones_temp = []
                if (zones.length > 0) {
                    zones.forEach(element => {
                        zones_temp.push({
                            name: element.attributes.name,
                            longitude: element.attributes.center.longitude, 
                            latitude: element.attributes.center.latitude,
                            radius: element.attributes.radius ? element.attributes.radius : '',
                            options: element.attributes.options ? element.attributes.options : '',
                            country: element.attributes.country ? element.attributes.country : '',
                            config: element.attributes.config ? element.attributes.config : '',
                        })
                    });
                }
                this.$store.commit("setZones", zones_temp);
                //eslint-disable-next-line
                //console.log(zones);
             
            } catch (error) {
                this.$snotify.error(`${error}` , 'Ciudad', {
                    timeout: 4000,
                    preventDuplicates: true
                });
            }
            
        },

        setZoneUser(zone){
            if (!zone || !zone.attributes.center.longitude || !zone.attributes.center.latitude || !zone.attributes.name) {
                this.$snotify.error(`La ciudad seleccionada actualmente no esta disponible` , 'Ciudad', {
                    timeout: 4000,
                    preventDuplicates: true
                });
                return;
            }
            let location = {
                name: zone.attributes.name,
                longitude: zone.attributes.center.longitude, 
                latitude: zone.attributes.center.latitude,
                radius: zone.attributes.radius ? zone.attributes.radius : '',
                options: zone.attributes.options ? zone.attributes.options : '',
                country: zone.attributes.country ? zone.attributes.country : '',
                config: zone.attributes.config ? zone.attributes.config : ''  
            }
            
            window.localStorage.setItem("festyZoneLocation", JSON.stringify(location));
            window.location.href = '/';
        }
    },
}
</script>

<style lang="css">
    .repeating-gradient {
        background-image: repeating-linear-gradient(-45deg,
                        rgba(255,0,0,.25),
                        rgba(255,0,0,.25) 5px,
                        rgba(0,0,255,.25) 5px,
                        rgba(0,0,255,.25) 10px
                      );
  }
</style>